import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { PageRoutes } from "./utils/pageRoutes";
import "./App.css";
import "./variables.scss";

// page routes
const Home = lazy(() => import("./pages/home/Home"));
const PrivacyPage = lazy(() => import("./pages/privacy/PrivacyPage"));
const TermsPage = lazy(() => import("./pages/terms/TermsPage"));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path={PageRoutes.home} element={<Home />} />
          <Route path={PageRoutes.privacyPolicy} element={<PrivacyPage />} />
          <Route path={PageRoutes.termsConditions} element={<TermsPage />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
